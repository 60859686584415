// This is the template for all the signs
import * as React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { TimelineIcon } from "../../images/icons";

// ------------> SignsGrid  <------------ //
const SignsGridNoFilter = ({ signs }) => {
  // ------> Fade Signs Items when apearing <------ //
  const [fadeItem, setFadeItem] = React.useState("30");
  // ------> Effect for Fade Signs <------ //
  React.useEffect(() => {
    setFadeItem(fadeItem === "30" && "100");
  }, [fadeItem]);

  return (
    <div className="relative">
      <div className=" text-center place-items-center grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-[2.6em] mt-[2.6em]">
        {/* <div className="grid place-items-center"> */}
        {signs.map((sign, index) => {
          // --->  Gatsby Image Const <--- //
          const image = getImage(
            sign.heroImage.localFile.childImageSharp.gatsbyImageData
          );

          return (
            <div key={index} data-item="true">
              <Link
                to={`/signs/${sign.slug}`}
                className={` items-center flex-col h-max p-0 overflow-hidden cursor-pointer opacity-${fadeItem} transition ease-in-out duration-300`}
              >
                <div className="group transition ease-in-out flex items-center justify-items-center flex-col h-max p-0 overflow-hidden cursor-pointer">
                  <div className="mb-[0.65em] grid-container place-items-center">
                    <div className="item-grid-content">
                      {sign.heroImage && (
                        <GatsbyImage
                          image={image}
                          alt=""
                          className="opacity-100 group-hover:opacity-10 duration-300"
                          imgStyle=""
                        />
                      )}
                    </div>
                    {/* Caption on hover */}
                    <div className="flex flex-col justify-center opacity-0 scale-95 group-hover:scale-100 group-hover:opacity-100 duration-300 text-white w-full h-full z-10 item-grid-overlay">
                      <div className=" capitalize">{sign.title}</div>
                    </div>
                  </div>
                  <div className="mt-[0.65em] opacity-100  group-hover:opacity-0 duration-300 ">
                    <div className="w-full flex justify-center translate-y-0 group-hover:translate-y-5 duration-300">
                      <TimelineIcon w={"25"} />
                    </div>
                    <div className="text-white text-base  mt-[1.3em]">
                      {sign.year}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SignsGridNoFilter;
