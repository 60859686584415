import * as React from "react";
import { graphql, navigate, Link } from "gatsby";
import SignsGridNoFilter from "../components/signGrid/signsGridNoFiler";
import Layout from "../components/layout";
import { ArrowRight } from "../images/icons";
import { slugify } from "../utils/slugify";
import { Seo } from "../components/seo";
const CategoryPageTemplate = (props) => {
  const category = props.data.strapiCategory;
  const nextSign = props.pageContext.nextCategorySlug;
  // const prevSign = props.pageContext.previousCategorySlug;

  return (
    <Layout
      location={props.location}
      title={props.data.site.siteMetadata.title}
    >
      <div className="text-white text-lg capitalize">
        <div>{category.category}</div>
      </div>
      <div style={{ marginBottom: `${1.3 * 6}rem` }}>
        <SignsGridNoFilter signs={category.signs} />
      </div>

      {/* ---> Back  Next buttons <--- */}
      <div className="flex justify-between ">
        {/* Back */}
        <button
          className={"text-amber-300"}
          // to={"/signs"}
          onClick={() => navigate(-1)}
        >
          <div className="capitalize group transition-colors flex items-center">
            <ArrowRight
              classes={
                "h-3.5 lg:h-4 rotate-180 pb-[0.2em] group-hover:text-white  mr-3 duration-300"
              }
            />
            <div className="group-hover:text-white duration-300">back</div>
          </div>
        </button>
        {/* Next */}
        <div className={`transition-colors text-amber-300`}>
          <Link
            className={"flex items-center capitalize group"}
            to={`/categories/${slugify(nextSign)}`}
          >
            <div className="group-hover:text-white mr-3 duration-300">Next</div>
            <ArrowRight
              classes={
                "h-3.5 lg:h-4 pt-[0.2em] group-hover:text-white duration-300"
              }
            />
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($category: String) {
    strapiCategory(category: { eq: $category }) {
      category
      signs {
        title
        slug
        year
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default CategoryPageTemplate;

// *---> SEO
export const Head = () => (
  <Seo
    title={"Signs' Categories"}
    description={"Exploring all the Signs"}
    keywords={"specualtive design, sign, sign design"}
  />
);
